/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
.item-empty .hover {
  display: none !important;
}
/* LEVEL 1 */
div.sub1 {
  width: 100%;
  text-align: center;
  margin: 66px 0 40px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.cb-elastic-content-small div.sub1 {
  margin: 46px 0 20px;
}
.sub1 > .item {
  display: inline-block;
  float: none;
  margin: 0 16px;
}
.sub1 > .item.init {
  margin-left: 0;
}
.sub1 > .item.exit {
  margin-right: 0;
}
.sub1 > .item:hover > .menu,
.sub1 > .item:focus > .menu {
  color: #e95c36;
}
div.sub1 .menu {
  text-transform: uppercase;
  line-height: 24px;
  color: #000;
  font-family: 'NexaRustSansBlack1', helvetica, sans-serif;
  letter-spacing: 0;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  text-decoration: none;
  color: #e95c36;
}
div.sub1 a.path {
  color: #e95c36;
}
/* LEVEL 2 */
div.sub2 {
  float: left;
  margin-right: -500px;
}
.sub2 > .item {
  float: none;
  display: block;
}
.sub2 > .item.init {
  margin-top: 18px;
}
div.sub2 .menu {
  font-size: 17px;
  font-family: 'NeueHaasGroteskDisplayPro55Roman', helvetica, sans-serif;
  text-transform: none;
  text-align: left;
  padding: 3px 0;
}
.cbe--edit-navigation div.sub1 > .item.path div.sub2 {
  display: block !important;
}
#cmsbox {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 15px;
  text-align: center;
}
#cmsbox .meta {
  margin-left: 15px;
  font-size: 12px;
  color: #f1947b;
  font-family: 'NexaRustSansBook', helvetica, sans-serif;
  letter-spacing: 0;
}
#cmsbox .meta:hover,
#cmsbox .meta:focus {
  color: #000;
  text-decoration: none;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}
.desk.footdesk {
  max-width: 100%;
}
.home {
  position: absolute;
  left: 40px;
  top: 20px;
  z-index: 2;
}
.cb-elastic-content-small .home {
  top: 7px;
}
.cb-elastic-content-small .home.home-large {
  opacity: 0;
  width: 0;
  -o-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  -moz-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  -webkit-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
}
.cb-elastic-content-small .home.home-small {
  opacity: 1;
  -o-transition: top 0.218s;
  -moz-transition: top 0.218s;
  -webkit-transition: top 0.218s;
  transition: top 0.218s;
}
@media only screen and (max-width: 1399px) {
  .home.home-large {
    opacity: 0;
    width: 0;
    -o-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -moz-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    -webkit-transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
    transition: top 0.218s, opacity 0.218s, width 0.218s 0.5s;
  }
  .home.home-small {
    opacity: 1;
    -o-transition: top 0.218s;
    -moz-transition: top 0.218s;
    -webkit-transition: top 0.218s;
    transition: top 0.218s;
  }
}
@media only screen and (max-width: 1399px) {
  #services {
    right: 5px;
  }
  #services .meta {
    padding-left: 15px;
    padding-right: 15px;
  }
  div.sub2 .menu {
    padding: 0;
  }
  .footpart {
    font-size: 17px;
  }
}
.side h3,
.cb-layout1 .main h3,
.cb-layout3 .main h3,
.cb-layout2 .main .unit h3 {
  font-size: 30px;
  padding: 20px 0;
}
.cb-layout2 .main .unit.pure.wide h3 {
  font-size: 50px;
  padding: 30px 0;
}
.side h4,
.cb-layout1 .main h4,
.cb-layout3 .main h4,
.cb-layout2 .main .unit h4 {
  padding: 20px 0;
  font-size: 26px;
}
.cb-layout2 .main .unit.pure.wide h4 {
  padding: 50px 0;
  font-size: 70px;
}
.side h5,
.cb-layout1 .main h5,
.cb-layout3 .main h5,
.cb-layout2 .main .unit h5 {
  font-size: 24px;
}
.cb-layout2 .main .unit.pure.wide h5 {
  font-size: 41px;
}
.maincontent {
  margin-top: 37px;
}
.moodcontent {
  top: 50%;
}
#services {
  position: absolute;
  right: 16px;
  top: 42px;
  z-index: 2;
  margin: 0;
}
.cb-elastic-content-small #services {
  top: 22px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.71022727%;
  margin-left: 0.71022727%;
}
.area .part,
.area > .grid table {
  margin-right: 0.71022727%;
  margin-left: 0.71022727%;
  width: 98.57954545%;
}
.area .tiny {
  width: 48.57954545%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 1.42045455%;
  margin-left: 1.42045455%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 97.15909091%;
}
.area > .slim .tiny {
  width: 97.15909091%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 0.44883303%;
  margin-left: 0.44883303%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 0.44883303%;
  margin-left: 0.44883303%;
  width: 99.10233393%;
}
.cb-layout2 .main .tiny {
  width: 32.43566727%;
}
.cb-layout2 .main > .slim {
  width: 33.33333333%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 1.3464991%;
  margin-left: 1.3464991%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 97.3070018%;
}
.cb-layout2 .main > .slim .tiny {
  width: 97.3070018%;
}
.base,
.south {
  width: 100%;
}
.base > .unit,
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.south h2,
.base .foot,
.south .foot {
  margin-right: 0.53763441%;
  margin-left: 0.53763441%;
}
.base .part,
.south .part,
.base > .grid table,
.south > .grid table {
  margin-right: 0.53763441%;
  margin-left: 0.53763441%;
  width: 98.92473118%;
}
.base .tiny,
.south .tiny {
  width: 48.92473118%;
}
.base > .slim,
.south > .slim {
  width: 50%;
}
.base > .slim h2,
.south > .slim h2,
.base > .slim .foot,
.south > .slim .foot,
.base > .slim .part,
.south > .slim .part,
.base > .slim.grid table,
.south > .slim.grid table {
  margin-right: 1.07526882%;
  margin-left: 1.07526882%;
}
.base > .slim .part,
.south > .slim .part,
.base > .slim.grid table,
.south > .slim.grid table {
  width: 97.84946237%;
}
.base > .slim .tiny,
.south > .slim .tiny {
  width: 97.84946237%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0.53418803%;
  margin-left: 0.53418803%;
}
.north .part,
.north > .grid table {
  margin-right: 0.53418803%;
  margin-left: 0.53418803%;
  width: 98.93162393%;
}
.north .tiny {
  width: 48.93162393%;
}
.north > .slim {
  width: 50%;
}
.north > .slim h2,
.north > .slim .foot,
.north > .slim .part,
.north > .slim.grid table {
  margin-right: 1.06837607%;
  margin-left: 1.06837607%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 97.86324786%;
}
.north > .slim .tiny {
  width: 97.86324786%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 63.76811594%;
  margin-left: -0.45289855%;
}
.cb-layout2 .main {
  width: 100.9057971%;
  margin-left: -0.45289855%;
}
.cb-layout1 .main {
  margin-top: 30px;
}
.side {
  float: right;
  width: 32.60869565%;
}
.cb-layout1 .side {
  margin-top: 7px;
}
.base,
.south {
  width: 101.08695652%;
  margin-left: -0.54347826%;
}
.north {
  width: 84.7826087%;
  margin-left: 7.60869565%;
  margin-right: 7.60869565%;
}
.main .seam,
.main .flat,
.main .edge {
  margin-left: 0.71022727%;
  margin-right: 0.71022727%;
  width: 98.57954545%;
}
.main .seam.slim,
.main .flat.slim,
.main .edge.slim {
  width: 48.57954545%;
}
.cb-layout2 .main .seam,
.cb-layout2 .main .flat,
.cb-layout2 .main .edge {
  margin-left: 0.44883303%;
  margin-right: 0.44883303%;
  width: 65.7989228%;
}
.cb-layout2 .main .seam.slim,
.cb-layout2 .main .flat.slim,
.cb-layout2 .main .edge.slim {
  width: 32.40574506%;
}
.north .seam,
.north .flat,
.north .edge {
  margin-left: 0.53418803%;
  margin-right: 0.53418803%;
  width: 98.93162393%;
}
.north .seam.slim,
.north .flat.slim,
.north .edge.slim {
  width: 48.93162393%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */